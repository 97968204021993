import React, {
  useState,
  useEffect,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { FaChevronDown, FaChevronUp, FaSearch } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../../services/interceptor";
import Loading from "../../Loading";
import { AuthContext } from "../../../context/AuthContext";

const UsersList = () => {
  const {
    getAllPateintData,
    allPatients,
    allPatientsLoading,
    allPatientsError,
    setAllPatients,
    setAllPatientsError,
    getUpcomingAppointments,
  } = useContext(AuthContext);

  const navigate = useNavigate();

  const [filteredUsers, setFilteredUsers] = useState([]);
  const [checkupInfo, setCheckupInfo] = useState("");
  const [age, setAge] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  // Appointment States
  const [appointmentDate, setAppointmentDate] = useState("");
  const [timeBlock, setTimeBlock] = useState("");
  const [reason, setReason] = useState("");
  const [otherReason, setOtherReason] = useState("");

  const timeBlocks = [
    "10:00 AM - 12:00 PM",
    "12:00 PM - 2:00 PM",
    "2:00 PM - 4:00 PM",
    "4:00 PM - 6:00 PM",
    "6:00 PM - 8:00 PM",
  ];

  const reasonOptions = [
    "Routine Check-Up",
    "Cleaning",
    "Fillings",
    "Extraction",
    "Orthodontics",
    "Other",
  ];

  const today = new Date();
  const maxDate = new Date(today);
  maxDate.setDate(today.getDate() + 14);

  // State to manage which tiles are expanded
  const [expandedTiles, setExpandedTiles] = useState({});

  // State for search term with debouncing
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  // Pagination States
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPageOptions = [5, 10, 15, 20];
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // Calculate total pages
  const totalPages = useMemo(() => {
    return Math.ceil(filteredUsers.length / itemsPerPage);
  }, [filteredUsers.length, itemsPerPage]);

  // Memoized paginated users
  const paginatedUsers = useMemo(() => {
    const start = (currentPage - 1) * itemsPerPage;
    return filteredUsers.slice(start, start + itemsPerPage);
  }, [filteredUsers, currentPage, itemsPerPage]);

  // Helper function to calculate age from date of birth
  const calculateAge = useCallback((dateOfBirth) => {
    const dob = new Date(dateOfBirth);
    const todayDate = new Date();
    let ageCalc = todayDate.getFullYear() - dob.getFullYear();
    const monthDifference = todayDate.getMonth() - dob.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && todayDate.getDate() < dob.getDate())
    ) {
      ageCalc--;
    }

    return ageCalc;
  }, []);

  // Handle search input change with debouncing
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300); // 300ms debounce

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Filter users based on search term
  useEffect(() => {
    if (debouncedSearchTerm.trim() === "") {
      setFilteredUsers(allPatients);
    } else {
      const lowerCaseTerm = debouncedSearchTerm.toLowerCase();
      const filtered = allPatients.filter((user) => {
        const email = user.email.toLowerCase();
        const name = user.userDetails?.name?.toLowerCase() || "";
        const role = user.role.toLowerCase();
        return (
          email.includes(lowerCaseTerm) ||
          name.includes(lowerCaseTerm) ||
          role.includes(lowerCaseTerm)
        );
      });
      setFilteredUsers(filtered);
    }
  }, [debouncedSearchTerm, allPatients]);

  // Fetch all users on component mount
  useEffect(() => {
    if (!allPatients.length) {
      getAllPateintData();
    } else {
      setFilteredUsers(allPatients);
    }
  }, [allPatients, getAllPateintData]);

  // Toggle the expansion of a user tile
  const toggleTile = (id) => {
    setExpandedTiles((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  // Open Modal for Chief Complaint
  const openModal = (user) => {
    setSelectedUser(user);
    if (user.userDetails?.dateOfBirth) {
      const userAge = calculateAge(user.userDetails.dateOfBirth);
      setAge(userAge);
    } else {
      setAge("N/A"); // Handle cases where DOB is missing
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCheckupInfo("");
    setAge(0); // Reset age if necessary
  };

  // Open Modal for Appointment
  const openAppointmentModal = (user) => {
    setSelectedUser(user);
    if (user.userDetails?.dateOfBirth) {
      const userAge = calculateAge(user.userDetails.dateOfBirth);
      setAge(userAge);
    } else {
      setAge("N/A"); // Handle cases where DOB is missing
    }
    setIsAppointmentModalOpen(true);
  };

  const closeAppointmentModal = () => {
    setIsAppointmentModalOpen(false);
    // Reset appointment form fields
    setAppointmentDate("");
    setTimeBlock("");
    setReason("");
    setOtherReason("");
  };

  // Handle Appointment Submission
  const handleAppointmentSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (!appointmentDate || !timeBlock || !reason) {
      toast.error("Please fill in all required fields.");
      return;
    }

    if (reason === "Other" && !otherReason.trim()) {
      toast.error("Please specify the reason for the visit.");
      return;
    }

    try {
      await API.post(`${process.env.REACT_APP_API_URL}/api/users/appointment`, {
        userId: selectedUser._id,
        date: appointmentDate,
        timeBlock,
        reason: reason === "Other" ? otherReason : reason,
      });
      getUpcomingAppointments();

      toast.success("Appointment created successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored",
      });

      closeAppointmentModal();
    } catch (error) {
      console.error(error);
      toast.error(
        error.response?.data?.message || "Failed to create appointment.",
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          theme: "colored",
        }
      );
    }
  };

  // Handle Chief Complaint Submission
  const handleSubmit = async () => {
    if (!checkupInfo) {
      toast.error("Chief complaint is required.");
      return;
    }

    try {
      const response = await API.post(
        `${process.env.REACT_APP_API_URL}/api/doctor/existing-user-opd`,
        {
          age: typeof age === "number" ? age : null, // Ensure age is a number
          checkupInfo,
          user: selectedUser._id,
          medicalDetails: selectedUser.medicalDetails._id,
          userDetails: selectedUser.userDetails._id,
        }
      );
      // Navigate to the OPD page with the response data
      navigate(`/dashboard/checkup/${response.data}`);

      toast.success("OPD created successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored",
      });

      closeModal();
    } catch (error) {
      console.error(error);
      setAllPatientsError(
        error.response?.data?.message || "Failed to create OPD."
      );
      toast.error(error.response?.data?.message || "Failed to create OPD.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored",
      });
      closeModal();
    }
  };

  // Handle editing a user
  const handleEdit = (id) => {
    navigate(`/dashboard/edit-user/${id}`);
  };

  // Handle deleting a user
  const handleDeleteUser = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user? This action cannot be undone."
    );
    if (!confirmDelete) return;

    try {
      await API.delete(`${process.env.REACT_APP_API_URL}/api/users/${id}`);

      // Remove the deleted user from both allPatients and filteredUsers states
      setAllPatients((prevUsers) =>
        prevUsers.filter((user) => user._id !== id)
      );
      setFilteredUsers((prevUsers) =>
        prevUsers.filter((user) => user._id !== id)
      );

      toast.success("User deleted successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored",
      });
    } catch (err) {
      console.error("Error deleting user:", err);
      toast.error(
        err.response && err.response.data.message
          ? err.response.data.message
          : "Failed to delete user.",
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          theme: "colored",
        }
      );
    }
  };

  // Fetch all users on component mount
  useEffect(() => {
    if (!allPatients.length) {
      getAllPateintData();
    }
  }, [allPatients.length, getAllPateintData]);

  // Handle OPD Creation (if needed)
  const handleOPDCreate = async (user) => {
    console.log("user: ", user);
    try {
      const userAge = calculateAge(user.userDetails.dateOfBirth);
      const response = await API.post(
        `${process.env.REACT_APP_API_URL}/api/doctor/existing-user-opd`,
        {
          age: userAge,
          checkupInfo,
          user: user._id,
          medicalDetails: user.medicalDetails._id,
          userDetails: user.userDetails._id,
        }
      );

      navigate(`/dashboard/checkup/${response.data}`);

      toast.success("OPD created successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored",
      });
    } catch (error) {
      console.error(error);
      setAllPatientsError(
        error.response?.data?.message || "Failed to create OPD."
      );
      toast.error(error.response?.data?.message || "Failed to create OPD.", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored",
      });
    }
  };

  // Animation variants for the tile expansion
  const variants = {
    collapsed: { height: 0, opacity: 0, overflow: "hidden" },
    expanded: { height: "auto", opacity: 1 },
  };

  // Animation variants for the tile container
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.05 },
    }),
  };

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle items per page change
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(parseInt(e.target.value));
    setCurrentPage(1); // Reset to first page when items per page changes
  };

  if (allPatientsLoading) {
    return <Loading />;
  }

  if (allPatientsError) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-50">
        <div className="text-red-500 text-xl">{allPatientsError}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen md:p-6 bg-gray-100">
      <div className="mx-auto p-4 bg-white shadow-md rounded-lg ">
        <h1 className="text-3xl font-bold text-center mb-8 text-gray-800">
          All Patients
        </h1>

        {/* Search Bar */}
        <div className="mb-6 flex justify-center">
          <div className="relative w-full max-w-md">
            <FaSearch className="absolute top-3 left-3 text-gray-400" />
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Search by email or name"
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              aria-label="Search Users"
            />
          </div>
        </div>

        {/* Pagination and Items Per Page */}
        <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
          {/* Items Per Page Selector */}
          <div className="flex items-center space-x-2 mb-2 sm:mb-0">
            <label htmlFor="itemsPerPage" className="font-medium">
              Items per Page:
            </label>
            <select
              id="itemsPerPage"
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              className="border rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm sm:text-base"
            >
              {itemsPerPageOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          {/* Pagination Controls */}
          <div className="flex space-x-2">
            <AnimatePresence>
              {Array.from({ length: totalPages }, (_, index) => (
                <motion.button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
                    currentPage === index + 1
                      ? "bg-blue-600 text-white"
                      : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }`}
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  aria-label={`Go to page ${index + 1}`}
                >
                  {index + 1}
                </motion.button>
              ))}
            </AnimatePresence>
          </div>
        </div>

        {/* Users List */}
        <div className="space-y-6">
          {paginatedUsers?.length > 0 ? (
            paginatedUsers
              .filter((user) => user.role === "patient")
              .map((user, index) => (
                <motion.div
                  key={user._id}
                  className="bg-white border border-gray-200 rounded-lg shadow-sm"
                  initial="hidden"
                  animate="visible"
                  custom={index}
                  variants={containerVariants}
                >
                  {/* Header */}
                  <div
                    className="flex justify-between items-center p-4 cursor-pointer"
                    onClick={() => toggleTile(user._id)}
                    aria-expanded={expandedTiles[user._id] || false}
                  >
                    <div>
                      <p className="text-xl font-semibold text-gray-800">
                        Name:{" "}
                        <span className="text-blue-600">
                          {user.userDetails?.name || "N/A"}
                        </span>
                      </p>
                      <p className="text-md text-gray-600">
                        <span className="font-medium">Email:</span> {user.email}
                      </p>
                      <p className="text-md text-gray-600">
                        <span className="font-medium">Account Status:</span>{" "}
                        {user.status.charAt(0).toUpperCase() +
                          user.status.slice(1)}
                      </p>
                      <p className="text-md text-gray-600">
                        <span className="font-medium">Created At: </span>
                        {new Date(user.createdAt).toLocaleString()}
                      </p>
                    </div>
                    <div>
                      {expandedTiles[user._id] ? (
                        <FaChevronUp className="h-6 w-6 text-gray-600" />
                      ) : (
                        <FaChevronDown className="h-6 w-6 text-gray-600" />
                      )}
                    </div>
                  </div>

                  {/* Expanded Content with Animation */}
                  <AnimatePresence>
                    {expandedTiles[user._id] && (
                      <motion.div
                        className="px-4 pb-4"
                        initial="collapsed"
                        animate="expanded"
                        exit="collapsed"
                        variants={variants}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                      >
                        <div className="mt-4 border-t pt-4">
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            {/* User Details */}
                            <div>
                              <h2 className="text-lg font-semibold mb-2 text-gray-700">
                                User Details
                              </h2>
                              <div className="space-y-1 text-gray-600">
                                <p>
                                  <span className="font-medium">Name:</span>{" "}
                                  {user.userDetails?.name || "N/A"}
                                </p>
                                <p>
                                  <span className="font-medium">Gender:</span>{" "}
                                  {user.userDetails?.gender || "N/A"}
                                </p>
                                <p>
                                  <span className="font-medium">
                                    Date of Birth:
                                  </span>{" "}
                                  {user.userDetails?.dateOfBirth
                                    ? new Date(
                                        user.userDetails.dateOfBirth
                                      ).toLocaleDateString()
                                    : "N/A"}
                                </p>
                                <p>
                                  <span className="font-medium">Contact:</span>{" "}
                                  {user.userDetails?.contact?.mobile || "N/A"}
                                </p>
                                <p>
                                  <span className="font-medium">Email:</span>{" "}
                                  {user.userDetails?.contact?.email || "N/A"}
                                </p>
                                <p>
                                  <span className="font-medium">Address:</span>{" "}
                                  {user.userDetails?.contact?.address || "N/A"}
                                </p>
                              </div>
                              <br />

                              {/* Emergency Contact */}
                              <div>
                                <h2 className="text-lg font-semibold mb-2 text-gray-700">
                                  Emergency Contact
                                </h2>
                                <div className="space-y-1 text-gray-600">
                                  <p>
                                    <span className="font-medium">Name:</span>{" "}
                                    {user.userDetails?.emergencyContact?.name ||
                                      "N/A"}
                                  </p>
                                  <p>
                                    <span className="font-medium">
                                      Relationship:
                                    </span>{" "}
                                    {user.userDetails?.emergencyContact
                                      ?.relationship || "N/A"}
                                  </p>
                                  <p>
                                    <span className="font-medium">Phone:</span>{" "}
                                    {user.userDetails?.emergencyContact
                                      ?.phone || "N/A"}
                                  </p>
                                </div>
                              </div>
                            </div>

                            {/* Medical Details */}
                            <div>
                              <h2 className="text-lg font-semibold mb-2 text-gray-700">
                                Medical Details
                              </h2>
                              <div className="space-y-1 text-gray-600">
                                <p>
                                  <span className="font-medium">
                                    Blood Group:
                                  </span>{" "}
                                  {user.medicalDetails?.bloodGroup || "N/A"}
                                </p>
                                <p>
                                  <span className="font-medium">
                                    Medical History:
                                  </span>{" "}
                                  {user.medicalDetails?.medicalHistory || "N/A"}
                                </p>
                                <div>
                                  <span className="font-medium">
                                    Medications:
                                  </span>
                                  {user.medicalDetails?.medications &&
                                  user.medicalDetails.medications.length > 0 ? (
                                    <ul className="list-disc list-inside ml-4">
                                      {user.medicalDetails.medications.map(
                                        (med, idx) => (
                                          <li key={idx}>
                                            {med.name} - {med.dosage} -{" "}
                                            {med.frequency}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    <p className="ml-4">
                                      No medications listed.
                                    </p>
                                  )}
                                </div>
                                <div>
                                  <span className="font-medium">
                                    Allergies:
                                  </span>
                                  {user.medicalDetails?.allergies &&
                                  user.medicalDetails.allergies.length > 0 ? (
                                    <ul className="list-disc list-inside ml-4">
                                      {user.medicalDetails.allergies.map(
                                        (allergy, idx) => (
                                          <li key={idx}>
                                            {allergy.allergen} -{" "}
                                            {allergy.reaction} (
                                            {allergy.severity})
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    <p className="ml-4">No allergies listed.</p>
                                  )}
                                </div>
                                <div>
                                  <span className="font-medium">
                                    Lifestyle & Habits:
                                  </span>
                                  <ul className="list-disc list-inside ml-4">
                                    <li>
                                      Smoking Status:{" "}
                                      {user.medicalDetails?.lifestyleAndHabits
                                        ?.smokingStatus || "N/A"}
                                    </li>
                                    <li>
                                      Alcohol Use:{" "}
                                      {user.medicalDetails?.lifestyleAndHabits
                                        ?.alcoholUse || "N/A"}
                                    </li>
                                    <li>
                                      Tobacco:{" "}
                                      {user.medicalDetails?.lifestyleAndHabits
                                        ?.tobacco || "N/A"}
                                    </li>
                                  </ul>
                                </div>
                                <p>
                                  <span className="font-medium">
                                    Past Dental History:
                                  </span>{" "}
                                  {user.medicalDetails?.pastDentalHistory ||
                                    "N/A"}
                                </p>
                                <p>
                                  <span className="font-medium">Notes:</span>{" "}
                                  {user.medicalDetails?.notes ||
                                    "No additional notes."}
                                </p>
                              </div>
                            </div>
                          </div>

                          {/* Previous OPD */}
                          <div className="mt-6">
                            <h2 className="text-lg font-semibold mb-2 text-gray-700">
                              Previous OPD
                            </h2>

                            {user?.opdForms && user.opdForms.length > 0 ? (
                              <ul className="list-disc list-inside ml-4">
                                {user.opdForms.map((opd, idx) => (
                                  <li key={idx}>
                                    <Link
                                      to={`/dashboard/checkup/${opd}`}
                                      className="text-blue-600 hover:underline"
                                    >
                                      {opd}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            ) : (
                              <p className="ml-4 text-gray-600">
                                No OPD records found.
                              </p>
                            )}
                          </div>

                          {/* Action Buttons */}
                          <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4 mt-6">
                            <button
                              className="flex items-center justify-center bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none text-sm sm:text-base"
                              onClick={() => openModal(user)}
                            >
                              Create OPD
                            </button>
                            <button
                              className="flex items-center justify-center bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none text-sm sm:text-base"
                              onClick={() => openAppointmentModal(user)}
                            >
                              Create Appointment
                            </button>
                          </div>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
              ))
          ) : (
            <div className="text-center text-gray-600">
              No users found matching your search.
            </div>
          )}
        </div>

        {/* Pagination Controls */}
        {totalPages > 1 && (
          <div className="flex flex-col sm:flex-row justify-between items-center mt-8">
            {/* Items Per Page Selector */}
            <div className="flex items-center space-x-2 mb-4 sm:mb-0">
              <label htmlFor="itemsPerPage" className="font-medium">
                Items per Page:
              </label>
              <select
                id="itemsPerPage"
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}
                className="border rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm sm:text-base"
              >
                {itemsPerPageOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            {/* Page Numbers */}
            <div className="flex space-x-2">
              <AnimatePresence>
                {Array.from({ length: totalPages }, (_, index) => (
                  <motion.button
                    key={index + 1}
                    onClick={() => handlePageChange(index + 1)}
                    className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
                      currentPage === index + 1
                        ? "bg-blue-600 text-white"
                        : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                    }`}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    aria-label={`Go to page ${index + 1}`}
                  >
                    {index + 1}
                  </motion.button>
                ))}
              </AnimatePresence>
            </div>
          </div>
        )}

        {/* Chief Complaint Modal */}
        {isModalOpen && selectedUser && (
          <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
            aria-modal="true"
            role="dialog"
            aria-labelledby="chief-complaint-modal"
          >
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full mx-4">
              <h2
                id="chief-complaint-modal"
                className="text-xl font-semibold mb-4"
              >
                Enter Chief Complaint
              </h2>
              <span className="font-medium">Age:</span>{" "}
              {age !== "N/A" ? age : "Not Available"}
              <textarea
                value={checkupInfo}
                onChange={(e) => setCheckupInfo(e.target.value)}
                placeholder="Describe the chief complaint..."
                className="w-full h-24 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mt-2"
                aria-label="Chief Complaint"
              ></textarea>
              <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-4 mt-4">
                <button
                  className="bg-gray-300 px-4 py-2 rounded-md hover:bg-gray-400 focus:outline-none text-sm sm:text-base"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none text-sm sm:text-base"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Appointment Modal */}
        {isAppointmentModalOpen && selectedUser && (
          <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
            aria-modal="true"
            role="dialog"
            aria-labelledby="appointment-modal"
          >
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full mx-4">
              <h2 id="appointment-modal" className="text-xl font-semibold mb-4">
                Create an Appointment
              </h2>
              <form onSubmit={handleAppointmentSubmit} className="space-y-4">
                <div>
                  <label
                    htmlFor="appointmentDate"
                    className="block text-gray-600 mb-1"
                  >
                    Preferred Appointment Date:
                  </label>
                  <input
                    type="date"
                    id="appointmentDate"
                    value={appointmentDate}
                    onChange={(e) => setAppointmentDate(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    min={today.toISOString().split("T")[0]}
                    max={maxDate.toISOString().split("T")[0]}
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="timeBlock"
                    className="block text-gray-600 mb-1"
                  >
                    Preferred Time Block:
                  </label>
                  <select
                    id="timeBlock"
                    value={timeBlock}
                    onChange={(e) => setTimeBlock(e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    required
                  >
                    <option value="" disabled>
                      Select a time block
                    </option>
                    {timeBlocks.map((block) => (
                      <option key={block} value={block}>
                        {block}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <label htmlFor="reason" className="block text-gray-600 mb-1">
                    Reason for Visit:
                  </label>
                  <select
                    id="reason"
                    value={reason}
                    onChange={(e) => {
                      setReason(e.target.value);
                      if (e.target.value !== "Other") setOtherReason("");
                    }}
                    className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    required
                  >
                    <option value="" disabled>
                      Select a reason
                    </option>
                    {reasonOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  {reason === "Other" && (
                    <input
                      type="text"
                      placeholder="Please specify"
                      value={otherReason}
                      onChange={(e) => setOtherReason(e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 mt-2"
                      required
                    />
                  )}
                </div>
                <div className="flex justify-end space-x-4">
                  <button
                    type="button"
                    className="bg-gray-300 px-4 py-2 rounded-md hover:bg-gray-400 focus:outline-none text-sm sm:text-base"
                    onClick={closeAppointmentModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none text-sm sm:text-base"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        <ToastContainer />
      </div>
    </div>
  );
};

export default UsersList;
