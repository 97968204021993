// src/components/Dashboard.jsx
import React, { useEffect, useState, useMemo, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import {
  FaClipboardList,
  FaUsers,
  FaCalendarAlt,
  FaBell,
} from "react-icons/fa";
import CalendarWidget from "../../CalendarWidget";
import Loading from "../../Loading";

const Dashboard = () => {
  const {
    appointments,
    appointmentsLoading,
    appointmentsError,
    getUpcomingAppointments,
  } = useContext(AuthContext);

  const [pendingUpdates, setPendingUpdates] = useState({});
  const [loadingUpdates, setLoadingUpdates] = useState({}); // Loader state for each appointment
  const [childDate, setChildDate] = useState("");

  useEffect(() => {
    setChildDate(Date(childDate));
  }, []);

  useEffect(() => {
    if (!appointments.length) {
      getUpcomingAppointments(); // Fetch only if no appointments available
    }
  }, [appointments]);

  // Function to receive data from child
  const handleDataFromChild = (data) => {
    setChildDate(data);
  };

  // Function to format timeBlock to a single time (optional)
  const formatTime = (timeBlock) => {
    // Assuming timeBlock is in the format "6:00 PM - 8:00 PM"
    // You can modify this function based on your requirements
    return timeBlock.split(" - ")[0]; // Returns "6:00 PM"
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString(undefined, options);
  };

  // Helper function to parse time string to minutes since midnight
  const parseTime = (timeStr) => {
    const [time, modifier] = timeStr.split(" ");
    let [hours, minutes] = time.split(":").map(Number);

    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    }
    if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    return hours * 60 + minutes;
  };

  // Memoized filtered and sorted appointments for today
  const todayAppointments = useMemo(() => {
    const today = new Date().toISOString().split("T")[0]; // "YYYY-MM-DD"

    return appointments
      .filter((appointment) => {
        const appointmentDate = new Date(appointment.date)
          .toISOString()
          .split("T")[0];
        return appointmentDate === today;
      })
      .sort((a, b) => {
        const timeA = parseTime(a.timeBlock.split(" - ")[0]);
        const timeB = parseTime(b.timeBlock.split(" - ")[0]);
        return timeA - timeB;
      });
  }, [appointments]);

  // Memoized filtered and sorted appointments for today
  const selectedDateAppointements = useMemo(() => {
    return appointments
      .filter((appointment) => {
        const appointmentDate = new Date(appointment.date);
        const today = new Date(childDate);

        if (
          appointmentDate.getFullYear() === today.getFullYear() &&
          appointmentDate.getMonth() === today.getMonth() &&
          appointmentDate.getDate() === today.getDate()
        ) {
          return appointmentDate;
        }
      })

      .sort((a, b) => {
        const timeA = parseTime(a.timeBlock.split(" - ")[0]);
        const timeB = parseTime(b.timeBlock.split(" - ")[0]);
        return timeA - timeB;
      });
  }, [appointments, handleDataFromChild]);

  // Helper function to parse date string to Date object without time
  const parseDate = (isoDate) => {
    const date = new Date(isoDate);
    // Reset time to midnight for accurate comparison
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  };

  // Memoized filtered and sorted appointments for upcoming
  const upcomingAppointments = useMemo(() => {
    const today = parseDate(new Date());

    return appointments
      .filter((appointment) => {
        const appointmentDate = parseDate(appointment.date);
        // Include appointments that are today or in the future
        return appointmentDate > today;
      })
      .sort((a, b) => {
        const dateA = parseDate(a.date);
        const dateB = parseDate(b.date);

        if (dateA.getTime() !== dateB.getTime()) {
          return dateA - dateB; // Sort by date
        }

        // If dates are the same, sort by time
        const timeA = parseTime(a.timeBlock.split(" - ")[0]);
        const timeB = parseTime(b.timeBlock.split(" - ")[0]);
        return timeA - timeB;
      });
  }, [appointments]);

  const pendingAppointmentsCount = useMemo(() => {
    return appointments.filter(
      (appointment) => appointment.status.toLowerCase() === "pending"
    ).length;
  }, [appointments]);

  const completedAppointmentsCount = useMemo(() => {
    return appointments.filter(
      (appointment) => appointment.status.toLowerCase().trim() === "completed"
    ).length;
  }, [appointments]);

  return (
    <div>
      {/* Main Content */}
      <div className="flex-1 md:p-6 overflow-y-auto">
        {/* Header */}
        <header className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-semibold text-gray-800">Dashboard</h1>
          {/* <div className="flex items-center space-x-4">
            <button className="relative">
              <FaBell className="h-6 w-6 text-gray-600" />
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">3</span>
            </button>
            <img
              src="https://via.placeholder.com/40"
              alt="User Avatar"
              className="w-10 h-10 rounded-full"
            />
          </div> */}
        </header>
        {/* Statistics */}
        <div className="bg-white p-4 rounded-lg shadow-md lg:col-span-2 my-5">
          <h2 className="text-xl font-semibold mb-4">Statistics</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4">
            <div className="bg-gray-100 p-4 rounded-lg">
              <h3 className="text-lg font-medium text-gray-700">
                Total Appointment
              </h3>
              <p className="text-2xl font-bold">{appointments.length}</p>
            </div>
            <div className="bg-indigo-100 p-4 rounded-lg">
              <h3 className="text-lg font-medium text-indigo-700">
                Completed Appointment
              </h3>
              <p className="text-2xl font-bold">{completedAppointmentsCount}</p>
            </div>
            <div className="bg-green-100 p-4 rounded-lg">
              <h3 className="text-lg font-medium text-green-700">
                Appointments Today
              </h3>
              <p className="text-2xl font-bold">{todayAppointments.length}</p>
            </div>
            <div className="bg-yellow-100 p-4 rounded-lg">
              <h3 className="text-lg font-medium text-yellow-700">
                Upcoming Appointments
              </h3>
              <p className="text-2xl font-bold">
                {upcomingAppointments.length}
              </p>
            </div>
            <div className="bg-red-100 p-4 rounded-lg">
              <h3 className="text-lg font-medium text-red-700">
                Pending Appointment
              </h3>
              <p className="text-2xl font-bold">{pendingAppointmentsCount}</p>
            </div>
          </div>
        </div>

        {/* Grid Layout */}
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-6 my-5">
          {/* Today's Appointments */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Today's Appointments</h2>
            {appointmentsLoading ? (
              <Loading />
            ) : appointmentsError ? (
              <p className="text-red-500">{appointmentsError}</p>
            ) : todayAppointments.length === 0 ? (
              <p>No appointments for today.</p>
            ) : (
              <ul className="max-h-64 overflow-y-auto divide-y pr-2">
                {todayAppointments.map((appointment) => (
                  <li
                    key={appointment._id}
                    className="flex justify-between py-2"
                  >
                    <span className="text-gray-600">{appointment.name}</span>
                    <span>
                      {formatDate(appointment.date)} -{" "}
                      {formatTime(appointment.timeBlock)}
                    </span>
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Upcoming Appointments */}

          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">
              Upcoming Appointments
            </h2>
            {appointmentsLoading ? (
              <Loading />
            ) : appointmentsError ? (
              <p className="text-red-500">{appointmentsError}</p>
            ) : upcomingAppointments.length === 0 ? (
              <p>No upcoming appointments .</p>
            ) : (
              <ul className="max-h-64 overflow-y-auto divide-y pr-2">
                {upcomingAppointments.map((appointment) => (
                  <li
                    key={appointment._id}
                    className="flex justify-between py-2"
                  >
                    <span className="text-gray-600">{appointment.name}</span>
                    <span>
                      {formatDate(appointment.date)} -{" "}
                      {formatTime(appointment.timeBlock)}
                    </span>
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Calendar */}
          <CalendarWidget sendDataToParent={handleDataFromChild} />

          {/* Doctor appointment list data for selected date */}
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">
              Appointments for {formatDate(childDate)}
            </h2>
            {appointmentsLoading ? (
              <Loading />
            ) : appointmentsError ? (
              <p className="text-red-500">{appointmentsError}</p>
            ) : selectedDateAppointements.length === 0 ? (
              <p>No appointments for selected Date.</p>
            ) : (
              <ul className="max-h-64 overflow-y-auto divide-y pr-2">
                {selectedDateAppointements.map((appointment) => (
                  <li
                    key={appointment._id}
                    className="flex justify-between py-2"
                  >
                    <span className="text-gray-600">{appointment.name}</span>
                    <span>
                      {formatDate(appointment.date)} -{" "}
                      {formatTime(appointment.timeBlock)}
                    </span>
                  </li>
                ))}
              </ul>
            )}
          </div>

          {/* Patients */}
          {/* <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Recent Patients</h2>
            <ul>
              <li className="flex items-center py-2 border-b">
                <img
                  src="https://via.placeholder.com/40"
                  alt="Patient"
                  className="w-10 h-10 rounded-full mr-4"
                />
                <span>Emily Davis</span>
              </li>
              <li className="flex items-center py-2 border-b">
                <img
                  src="https://via.placeholder.com/40"
                  alt="Patient"
                  className="w-10 h-10 rounded-full mr-4"
                />
                <span>William Brown</span>
              </li>
              <li className="flex items-center py-2">
                <img
                  src="https://via.placeholder.com/40"
                  alt="Patient"
                  className="w-10 h-10 rounded-full mr-4"
                />
                <span>Olivia Wilson</span>
              </li>
            </ul>
          </div> */}

          {/* Notifications */}
          {/* <div className="bg-white p-6 rounded-lg shadow-md lg:col-span-2">
            <h2 className="text-xl font-semibold mb-4">Notifications</h2>
            <ul>
              <li className="flex items-center py-2 border-b">
                <FaBell className="h-6 w-6 text-indigo-500 mr-4" />
                <span>New appointment booked by John Doe at 3:00 PM.</span>
              </li>
              <li className="flex items-center py-2 border-b">
                <FaBell className="h-6 w-6 text-green-500 mr-4" />
                <span>Patient Jane Smith completed her treatment.</span>
              </li>
              <li className="flex items-center py-2">
                <FaBell className="h-6 w-6 text-red-500 mr-4" />
                <span>Appointment cancellation by Mike Johnson.</span>
              </li>
            </ul>
          </div> */}
        </div>

        {/* Grid Layout */}
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
          {/* Patients */}
          {/* <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl font-semibold mb-4">Recent Patients</h2>
            <ul>
              <li className="flex items-center py-2 border-b">
                <img
                  src="https://via.placeholder.com/40"
                  alt="Patient"
                  className="w-10 h-10 rounded-full mr-4"
                />
                <span>Emily Davis</span>
              </li>
              <li className="flex items-center py-2 border-b">
                <img
                  src="https://via.placeholder.com/40"
                  alt="Patient"
                  className="w-10 h-10 rounded-full mr-4"
                />
                <span>William Brown</span>
              </li>
              <li className="flex items-center py-2">
                <img
                  src="https://via.placeholder.com/40"
                  alt="Patient"
                  className="w-10 h-10 rounded-full mr-4"
                />
                <span>Olivia Wilson</span>
              </li>
            </ul>
          </div>  */}

          {/* Notifications */}
          {/* <div className="bg-white p-6 rounded-lg shadow-md lg:col-span-2">
            <h2 className="text-xl font-semibold mb-4">Notifications</h2>
            <ul>
              <li className="flex items-center py-2 border-b">
                <FaBell className="h-6 w-6 text-indigo-500 mr-4" />
                <span>New appointment booked by John Doe at 3:00 PM.</span>
              </li>
              <li className="flex items-center py-2 border-b">
                <FaBell className="h-6 w-6 text-green-500 mr-4" />
                <span>Patient Jane Smith completed her treatment.</span>
              </li>
              <li className="flex items-center py-2">
                <FaBell className="h-6 w-6 text-red-500 mr-4" />
                <span>Appointment cancellation by Mike Johnson.</span>
              </li>
            </ul>
          </div>  */}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
